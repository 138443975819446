var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidInputType = void 0;

function isValidInputType(cardNumber) {
  return typeof cardNumber === "string" || cardNumber instanceof String;
}

exports.isValidInputType = isValidInputType;
export default exports;