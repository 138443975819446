var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findBestMatch = void 0;

function hasEnoughResultsToDetermineBestMatch(results) {
  var numberOfResultsWithMaxStrengthProperty = results.filter(function (result) {
    return result.matchStrength;
  }).length;
  /*
   * if all possible results have a maxStrength property that means the card
   * number is sufficiently long enough to determine conclusively what the card
   * type is
   * */

  return numberOfResultsWithMaxStrengthProperty > 0 && numberOfResultsWithMaxStrengthProperty === results.length;
}

function findBestMatch(results) {
  if (!hasEnoughResultsToDetermineBestMatch(results)) {
    return null;
  }

  return results.reduce(function (bestMatch, result) {
    if (!bestMatch) {
      return result;
    }
    /*
     * If the current best match pattern is less specific than this result, set
     * the result as the new best match
     * */


    if (Number(bestMatch.matchStrength) < Number(result.matchStrength)) {
      return result;
    }

    return bestMatch;
  });
}

exports.findBestMatch = findBestMatch;
export default exports;